import React from "react"
import SkillsStyles from "./skills.module.scss"

const SkillsSplash = () => (
  <>
    {/* <div className={SkillsStyles.bill} /> */}
    <div className={SkillsStyles.splash}>
      <div className={SkillsStyles.aligner}>
        <h1>Tech</h1>
        <h2>I grew up with it, <br />
          and I'll grow old <br />
          with it.
        </h2>
      </div>
    </div>
  </>
)

export default SkillsSplash
